.video-player-tutorial {
    background-color: rgba(0, 0, 0, 0.712);
    // padding: 12px;
    // display: none;
    justify-content: center;
    align-items: center;
    height: 350px;
  
    // #video-player-btn-close {
    //   color: white;
    //   font-weight: bold;
    //   position: absolute;
    //   top: 20px;
    //   right: 20px;
    //   z-index: 1000;
      cursor: pointer;
    // }
  
    .example_video_tutorial {
      height: 350px;
      width: 100%;
    }
    
    // #video-player-btn-close:hover {
    //   color: red;
     
    // }
  
    @media only screen and (max-width: 991px) {
      .example_video_tutorial {
        width: 95%;
      }
    }
  
  }
#video_externo {
  color: yellow;
  text-decoration: underline;
  font-size: 17px;
}
.tutorial_button_title, .blocos {
  font-size: 18px;

  @media screen and (min-width:1200px) and (max-width:1400px) {
    font-size: 15px;
  }
  @media screen and (min-width:1050px) and (max-width:1199px) {
    font-size: 11px;
  }
  @media screen and (min-width:992px) and (max-width:1049px) {
    font-size: 9px;
  }
 
}
#answer_choose {

 
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 15px;
  font-size: 11px;
}
.color_red {
  border: 2px dashed #FA8072;
  background-color: rgba(255, 0, 0, 0.2);
}
.color_green {
  border: 2px dashed #32CD32;
  background-color: rgba(0, 255, 0, 0.2);
}