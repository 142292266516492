#custom-video-player {
  background-color: rgba(0, 0, 0, 0.712);
  // padding: 12px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;

  #video-player-btn-close {
    color: white;
    font-weight: bold;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
  }

  #example_video {
    width: 75%;
  }
  
  #video-player-btn-close:hover {
    color: red;
   
  }

  @media only screen and (max-width: 991px) {
    #example_video {
      width: 95%;
    }
  }

}