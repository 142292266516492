//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    padding: get($content-spacing, desktop) 0;

    // Toolbar enabled and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-fixed) & {
      padding-top: 0;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .content {
    padding: get($content-spacing, tablet-and-mobile) 0;

    // Toolbar enabled mode and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) & {
      padding-top: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .content {
    padding: get($content-spacing, desktop) 0;

    // Toolbar enabled and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-fixed) & {
      padding-top: 0;
    }
  }
  input{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  select {
    margin-bottom: 5px;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(md) {
  .content {
    padding: get($content-spacing, tablet-and-mobile) 0;
    font-size: 75%;

    // Toolbar enabled mode and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) & {
      padding-top: 0;
    }
    #positionButtonCalc{
      margin-top: 10px;
    }
    #positionButtonCalc button{
      margin: 0;
    }
  }
  input{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  select {
    margin-bottom: 5px;
  }
 
}
