//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';


.cut-text {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reportDegreeeAction { cursor: pointer; }
.reportDegreeeAction:hover { color: #EEE346; }

// .button_as_link {
//   padding: 5px 10px;
//   background-color: transparent;
//   border: none;
//   text-decoration: underline;
// }
// .button_as_link:hover {
//   color: blue;
// }

.bg-ram {
  background-color: #e4e6ef;
}
.bg-ram-rb {
  background-color: #47be7d;
}
.bg-ram-rm {
  background-color: #EEE346;
}
.bg-ram-ra {
  background-color: #ffaa3b;
}
.bg-ram-re {
  background-color: #d9214e;
}
